import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick.js';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import '../styles/index-home.css';
import 'jquery-confirm/js/jquery-confirm.js'
import 'jquery-confirm/css/jquery-confirm.css'
import $ from "jquery";

const dir = $('html').attr('dir');
const _dir1 = dir == 'rtl' ? "right" : "left";
const _dir2 = dir == 'rtl' ? "left" : "right";
const arrow1 = 'icon-chevron-left list-5-arrow ' + _dir1;
const arrow2 = 'icon-chevron-right1 list-5-arrow ' + _dir2;
$(document).ready(function (e) {
    $('.list-1-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        rtl: dir == "rtl" ? true : false,
        fade: true,
        draggable: false,
        autoplaySpeed: 3000,
        speed: 500,
        cssEase: 'ease-in-out',
        prevArrow: '<i class="' + arrow1 + '"></i>',
        nextArrow: '<i class="' + arrow2 + '"></i>',
        dots: true,
    }).on('setPosition', function (event, slick) {
        $('.list-1-slider .list-1-slider-item-desc').removeClass('active');
        $('.list-1-slider .slick-active .list-1-slider-item-desc').addClass('active');
    });
    $('.list-4-content').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        rtl: dir == "rtl" ? true : false,
        draggable: true,
        autoplaySpeed: 3000,
        speed: 500,
        cssEase: 'ease-in-out',
        prevArrow: '',
        nextArrow: '',
        dots: true,
        responsive: [{
            breakpoint: 800, settings: {
                nextArrow: "", prevArrow: "", dots: true, slidesToShow: 2,
            }
        },],
    }).on('setPosition', function (event, slick) {
        $('.list-1-slider .list-1-slider-item-desc').removeClass('active');
        $('.list-1-slider .slick-active .list-1-slider-item-desc').addClass('active');
    });
    $('.list-2 .container').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        rtl: dir == "rtl" ? true : false,
        fade: true,
        draggable: false,
        autoplaySpeed: 3000,
        speed: 500,
        cssEase: 'ease-in-out',
        prevArrow: '<i class=""></i>',
        nextArrow: '<i class=""></i>',
        dots: true,
        responsive: [{
            breakpoint: 800, settings: {
                nextArrow: "", prevArrow: "", dots: true,
            }
        },],
    }).on('setPosition', function (event, slick) {
        $('.list-1-slider .list-1-slider-item-desc').removeClass('active');
        $('.list-1-slider .slick-active .list-1-slider-item-desc').addClass('active');
    });
    $('.list-3 .container').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        rtl: dir == "rtl" ? true : false,
        fade: true,
        draggable: false,
        autoplaySpeed: 3000,
        speed: 500,
        cssEase: 'ease-in-out',
        prevArrow: '<i class=""></i>',
        nextArrow: '<i class=""></i>',
        dots: true,
        responsive: [{
            breakpoint: 800, settings: {
                nextArrow: "", prevArrow: "", dots: true,
            }
        },],
    }).on('setPosition', function (event, slick) {
        $('.list-1-slider .list-1-slider-item-desc').removeClass('active');
        $('.list-1-slider .slick-active .list-1-slider-item-desc').addClass('active');
    });
    $('.list-7-wrapper').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        rtl: dir == "rtl" ? true : false,
        draggable: false,
        autoplaySpeed: 4000,
        prevArrow: '<i class="' + arrow1 + '"></i>',
        nextArrow: '<i class="' + arrow2 + '"></i>',
        responsive: [{
            breakpoint: 1024, settings: {
                slidesToShow: 2,
            }
        }, {
            breakpoint: 800, settings: {
                slidesToShow: 1, nextArrow: "", prevArrow: "", dots: true,
            }
        },],

    });
    $('.list-5-wrapper').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        rtl: dir == "rtl" ? true : false,
        draggable: false,
        autoplaySpeed: 4000,
        prevArrow: '<i class="' + arrow1 + '"></i>',
        nextArrow: '<i class="' + arrow2 + '"></i>',
    }).on('setPosition', function (event, slick) {
        $('.list-5 .list-5-item-sec-1').removeClass('active');
        $('.list-5 .list-5-item-sec-2').removeClass('active');
        $('.list-5 .slick-active .list-5-item-sec-1').addClass('active');
        $('.list-5 .slick-active .list-5-item-sec-2').addClass('active');
    });
    let _window_width = $(window).width();
    let counter = 0;

    $('.list-9-wrapper').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        rtl: dir == "rtl" ? true : false,
        autoplaySpeed: 4000,
        prevArrow: '<i class="' + arrow1 + '"></i>',
        nextArrow: '<i class="' + arrow2 + '"></i>',
    }).on('setPosition', function (event, slick) {
        $('.list-5 .list-5-item-sec-1').removeClass('active');
        $('.list-5 .list-5-item-sec-2').removeClass('active');
        $('.list-5 .slick-active .list-5-item-sec-1').addClass('active');
        $('.list-5 .slick-active .list-5-item-sec-2').addClass('active');
        counter++;
        let imageUrl = "/web/assets/media/phone-bg.b22d2ca2.svg?v=" + counter;
        $('.list-9  .list-5-item-sec-2.animated-tri').css('background-image', 'none');
        let background_image = $('.list-9  .slick-active .list-5-item-sec-2.animated-tri');
        background_image.css('background-image', 'url(' + imageUrl + ')');
    });
    $('.list-11.home .list-11-wrapper').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        rtl: dir == "rtl" ? true : false,
        draggable: false,
        autoplaySpeed: 4000,
        prevArrow: '<i class="' + arrow1 + '"></i>',
        nextArrow: '<i class="' + arrow2 + '"></i>',
        responsive: [{
            breakpoint: 1024, settings: {
                slidesToShow: 2,

            }
        }, {
            breakpoint: 800, settings: {
                slidesToShow: 1, nextArrow: "", prevArrow: "", dots: true,
            }
        },],

    }).on('setPosition', function (event, slick) {
        slick.$slides.css('height', slick.$slideTrack.height() + 'px');
    });
    $('.list-5 .slick-active .list-5-item-sec-1').addClass('active');
    $('.list-5 .slick-active .list-5-item-sec-2').addClass('active');


    $(".slick-dots button").html('');
    $('table').closest('div').css({'overflow': 'hidden'});

    $('body').on('click', '.list-1-item', function (e) {
        let _my_item = $(this).find('a.gallery-item:first-child');
        _my_item.click();
    });
    $('.list-11.home2 .list-11-wrapper').slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: false,
        rtl: dir == "rtl" ? true : false,
        autoplaySpeed: 4000,
        prevArrow: "",
        nextArrow: "",
        dots: true,
        responsive: [{
            breakpoint: 1024, settings: {
                slidesToShow: 2,

            }
        }, {
            breakpoint: 800, settings: {
                slidesToScroll: 1, slidesToShow: 1,
            }
        },],

    }).on('setPosition', function (event, slick) {
        slick.$slides.css('height', slick.$slideTrack.height() + 'px');
    });
    $('#contact_us_form').on('submit', function (e) {
        e.preventDefault();
        $.ajax({
            url: "/contact_us.php",
            data: $(this).serialize(),
            method: "post",
            dataType: 'json',
            beforeSend: function () {
                $('.loader-1').fadeIn();
            },
            success: function (results) {
                $.confirm({
                    title: '' + results.title + '',
                    content: "",
                    columnClass: 'col-sm-12',
                    type: 'green',
                    buttons: {
                        ok: {
                                text: results.button_text, btnClass: 'btn-primary', keys: ['enter'], action: function () {
                                    location.href="/";
                            }
                        }
                    }
                });
            },
            error: function (response) {
                // let _response = response.responseJSON;
                // let _errors = _response.errors;
                // let _message = _response.message;
                // $('.required-label').remove();
                // $.each(_errors, function (_element, _label) {
                //     let _field = $('[name="' + _element + '"]');
                //     let recaptcha_field = $('.g-recaptcha');
                //     let _requiredLabelWrapper = _field.closest('.input-wrapper');
                //     let _requiredLabelWrapperRecapcha = recaptcha_field.closest('.input-wrapper');
                //     _requiredLabelWrapper.remove('.required-label');
                //     _requiredLabelWrapperRecapcha.remove('.required-label');
                //     _requiredLabelWrapper.append('<span class="required-label">' + _label + '</span>');
                //     if (_element == 'recaptcha') {
                //         _requiredLabelWrapperRecapcha.append('<span class="required-label">' + _label + '</span>');
                //
                //     }
                // });
                // $.confirm({
                //     title: '' + _response.title + '',
                //     content: _message,
                //     type: 'red',
                //     columnClass: 'col-sm-12',
                //     buttons: {
                //         ok: {
                //             text: "Ok",
                //             btnClass: 'btn-primary',
                //             keys: ['enter'],
                //             action: function () {
                //
                //             }
                //         }
                //     }
                // });
            },
            complete: function () {
                $('.loader-1').fadeOut();
            }
        });
    })

});
